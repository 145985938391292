import { addNavMenuItem } from '@vendure/admin-ui/core'

import { Permission } from './generated/ui-types'

export default [
    addNavMenuItem(
        {
            id: 'ebics',
            label: 'Payment Issues',
            routerLink: ['/extensions/ebics'],
            icon: 'sad-face',
            requiresPermission: Permission.UpdateOrder,
        },
        'sales',
    ),
]
