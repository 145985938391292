import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextKycStatesDocument, TransitionKycToStateDocument } from '../generated/ui-types'

@Injectable()
export class KycStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query(GetNextKycStatesDocument, {
            id,
        })
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate(TransitionKycToStateDocument, {
            id,
            state,
        })
    }
}
