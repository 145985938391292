import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import {
    DataService,
    FormInputComponent,
    ModalService,
    NotificationService,
    SharedModule,
} from '@vendure/admin-ui/core'
import { EMPTY, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { Customer, CustomFieldConfig } from '../generated/ui-types'
import { KycStateSelectDialogComponent } from '../kyc-state-select-dialog/kyc-state-select-dialog.component'
import { AddressStateDataService } from '../providers/address-state-data.service'

@Component({
    selector: 'address-state-field',
    templateUrl: './address-state-field.component.html',
    styleUrls: ['./address-state-field.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class AddressStateFieldComponent implements FormInputComponent, OnInit {
    readonly: boolean
    nextStates$: Observable<string[]>
    config: Observable<CustomFieldConfig>
    entityId: string

    formControl: FormControl

    constructor(
        private dataService: DataService,
        private addressStateDataService: AddressStateDataService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
    ) {}

    async manuallyTransitionToState() {
        return this.modalService
            .fromComponent(KycStateSelectDialogComponent, {
                locals: {
                    nextStates: this.nextStates$,
                    cancellable: true,
                    message:
                        'Manually transition the kyc to another state. Note that order states are governed by rules which may prevent certain transitions.',
                },
                closable: true,
                size: 'md',
            })
            .pipe(
                switchMap(result => {
                    if (result) {
                        return this.transitionToStateOrThrow(this.entityId, result)
                    } else {
                        return EMPTY
                    }
                }),
            )
            .subscribe()
    }
    private transitionToStateOrThrow(orderId: string, state: string) {
        return this.addressStateDataService.transitionToState(orderId, state).pipe(
            map(({ transitionAddressToState }) => {
                switch (transitionAddressToState?.__typename) {
                    case 'Customer':
                        return (transitionAddressToState as Customer)?.customFields?.kycState
                    case 'OomniumCustomerStateTransitionError':
                        this.notificationService.error(transitionAddressToState?.transitionError)
                        throw new Error(transitionAddressToState?.transitionError)
                }
            }),
        )
    }

    ngOnInit() {
        this.nextStates$ = this.route.data.pipe(
            switchMap(data => data.detail.entity),
            switchMap((entity: any) => {
                if (entity) {
                    this.entityId = entity.id
                    return this.addressStateDataService.getNextStates(entity.id as string).mapStream(data => {
                        return data.nextAddressStates
                    })
                }
                this.formControl.setValue('Unapproved')
                return of([])
            }),
        )
        this.nextStates$.subscribe()
    }
}
