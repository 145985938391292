export const extensionRoutes = [  {
    path: 'extensions/pages',
    loadChildren: () => import('./extensions/16aa5aad46b948ad463994d41ff3baaf7adb21d076b4aa446a8acfd758f1a508/routes'),
  },
  {
    path: 'extensions/ebics',
    loadChildren: () => import('./extensions/2c80dcc65df189dfa80703b4db4793ba556cea089709332815b8766eb2323c3e/routes'),
  },
  {
    path: 'extensions/testimonials',
    loadChildren: () => import('./extensions/f36ad3df3577f7a40e114f871bed5a6800eb47da1909815a3a8d13c7415ae589/routes'),
  },
  {
    path: 'extensions/reservations',
    loadChildren: () => import('./extensions/480df289cc08ee217b122b63bac78843cca8abe681e9dababe68924899103ed1/routes'),
  }];
