import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { CustomFieldConfigType, CustomFieldControl, SharedModule } from '@vendure/admin-ui/core'

@Component({
    selector: 'pxl-code-link',
    template: `
        {{ formControl.value }}
        |
        <a *ngIf="formControl.value && (['ReadCustomer'] | hasPermission)" [href]="link">Get Zip</a>
        |
        <a *ngIf="formControl.value && (['ReadCustomer'] | hasPermission)" [href]="regetlink"
            >Redownload Zip from PXL</a
        >

        <div *ngIf="!formControl.value">Not set yet</div>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class PxlCodeComponent implements CustomFieldControl {
    readonly: boolean
    config: CustomFieldConfigType
    formControl: FormControl

    get productId(): string | null {
        return this.route.snapshot.paramMap.get('id')
    }
    get link(): string {
        return `/pxl/zip/${this.route.snapshot.paramMap.get('id')}`
    }
    get regetlink(): string {
        return `/pxl/zip/${this.route.snapshot.paramMap.get('id')}/reget`
    }

    constructor(private route: ActivatedRoute) {}
}
