import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/16aa5aad46b948ad463994d41ff3baaf7adb21d076b4aa446a8acfd758f1a508/providers';
import SharedProviders_1_0 from './extensions/2c80dcc65df189dfa80703b4db4793ba556cea089709332815b8766eb2323c3e/providers';
import SharedProviders_2_0 from './extensions/2ff904dcc2a63a42707cf0190b03d060e4d41991d4f83991087c0a9636aec85f/providers';
import SharedProviders_3_0 from './extensions/244aeb1468afbbec815c1c9ea6cc218c7264e81731aa300937c191c229adb46f/providers';
import SharedProviders_4_0 from './extensions/f36ad3df3577f7a40e114f871bed5a6800eb47da1909815a3a8d13c7415ae589/providers';
import SharedProviders_5_0 from './extensions/480df289cc08ee217b122b63bac78843cca8abe681e9dababe68924899103ed1/providers';
import SharedProviders_6_0 from './extensions/fba9aa1e811c771306509747627ddae8cf00cf02887307d232d2e29a4281d457/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_4_0, ...SharedProviders_5_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
