import { addNavMenuItem, registerDashboardWidget, registerFormInputComponent } from '@vendure/admin-ui/core'

import { ReservationCountLinkComponent } from './components/reservation-count-link/reservation-count-link.component'
import { Permission } from './generated/ui-types'

export default [
    addNavMenuItem(
        {
            id: 'reservations',
            label: 'Reservations',
            routerLink: ['/extensions/reservations'],
            icon: 'star',
        },
        'catalog',
    ),
    registerDashboardWidget('reservations', {
        title: 'Latest reservations (Oomnium)',
        requiresPermissions: [Permission.ReadCustomer],
        supportedWidths: [4, 6, 8, 12],
        loadComponent: () =>
            import('./widgets/reservations-widget/reservations-widget.component').then(
                m => m.ReservationsWidgetComponent,
            ),
    }),
    registerFormInputComponent('reservations-count-link', ReservationCountLinkComponent),
]
