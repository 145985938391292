import gql from 'graphql-tag'

export const REJECT_ORDER = gql`
    mutation RejectOrder($orderId: ID!, $reason: String!) {
        rejectOrder(orderId: $orderId, reason: $reason) {
            success
            message
        }
    }
`

export const DELETE_ORDER = gql`
    mutation DeleteOrder($orderId: ID!) {
        deleteOrder(orderId: $orderId) {
            success
            message
        }
    }
`

export const OOMNIUM_ORDER_FIELDS = gql`
    query OomniumOrderFields($orderId: ID!) {
        order(id: $orderId) {
            customer {
                id
            }
            state
            id
            reference
            mtanChecked
            correctSigningOrder
            correctSigningPayment
            lines {
                id
            }
            payments {
                id
            }
        }
    }
`
