import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FormInputComponent, SharedModule } from '@vendure/admin-ui/core'
import { DefaultFormComponentConfig } from '@vendure/common/lib/shared-types'

import { VdrCkEditorComponent } from '../vdr-ck-editor/vdr-ck-editor'
import { StringCustomFieldConfig } from '@vendure/core'

@Component({
    selector: 'ck-editor-form-input',
    templateUrl: './ck-editor-form-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, VdrCkEditorComponent],
})
export class CkEditorFormInputComponent implements FormInputComponent<StringCustomFieldConfig> {
    readonly: boolean
    formControl: FormControl
    config: DefaultFormComponentConfig<'ck-editor-form-input'>
}
