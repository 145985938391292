import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { CustomFieldConfigType, CustomFieldControl, SharedModule } from '@vendure/admin-ui/core'

@Component({
    selector: 'kb-review-count-link',
    template: `
        {{ formControl.value }}
        <a [routerLink]="['/extensions/testimonials/product', productId]">View testimonials</a>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ReviewCountLinkComponent implements CustomFieldControl {
    readonly: boolean
    config: CustomFieldConfigType
    formControl: FormControl

    get productId(): string | null {
        return this.route.snapshot.paramMap.get('id')
    }

    constructor(private route: ActivatedRoute) {}
}
