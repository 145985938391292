import {
    addNavMenuItem,
    registerDashboardWidget,
    registerFormInputComponent,
    setDashboardWidgetLayout,
} from '@vendure/admin-ui/core'

import { ReviewCountLinkComponent } from './components/review-count-link/review-count-link.component'
import { Permission } from './generated/ui-types'

export default [
    addNavMenuItem(
        {
            id: 'reviews',
            label: 'Testimonials',
            routerLink: ['/extensions/testimonials'],
            icon: 'star',
        },
        'marketing',
    ),
    registerDashboardWidget('reviews', {
        title: 'Latest testimonials (Oomnium)',
        supportedWidths: [4, 6, 8, 12],
        requiresPermissions: [Permission.ReadProduct],
        loadComponent: () =>
            import('./widgets/reviews-widget/reviews-widget.component').then(m => m.ReviewsWidgetComponent),
    }),
    setDashboardWidgetLayout([
        { id: 'campaignStats', width: 6 },
        { id: 'orderSummary', width: 6 },
        { id: 'latestOrdersOomnium', width: 6 },
        { id: 'customerstate', width: 6 },
        { id: 'reviews', width: 6 },

        { id: 'welcome', width: 12 },
    ]),
    registerFormInputComponent('review-count-link', ReviewCountLinkComponent),
]
