import {
    addActionBarDropdownMenuItem,
    registerDashboardWidget,
    registerFormInputComponent,
} from '@vendure/admin-ui/core'
import { Product } from '@vendure/core'

import { Permission } from './generated/ui-types'
import { ProductStateFieldComponent } from './product-state-field/product-state-field.component'
import { ProductStateDataService } from './providers/product-state-data.service'

export default [
    registerDashboardWidget('campaignStats', {
        title: 'Campaign Stats',
        supportedWidths: [6, 8, 12],
        requiresPermissions: [Permission.ReadProduct],
        loadComponent: () => import('./widgets/stats-widget/stats-widget.component').then(m => m.StatsWidgetComponent),
    }),
    ProductStateDataService,
    registerFormInputComponent('product-state-field', ProductStateFieldComponent),
    registerFormInputComponent('product-state-select-dialog', ProductStateFieldComponent),
    addActionBarDropdownMenuItem({
        id: 'print-invoice',
        label: 'Recalculate',
        locationId: 'product-detail',
        requiresPermission: Permission.UpdateProduct,
        onClick: async (event, context) => {
            context.route.snapshot.data.detail.entity.subscribe(async (product: Product) => {
                const url = `/product/recalculate/${product.id}`
                await fetch(url)
                window.location.reload()
            })
        },
    }),
    addActionBarDropdownMenuItem({
        id: 'presaleKey',
        label: 'presaleKey',
        locationId: 'product-detail',
        requiresPermission: Permission.ReadProduct,
        onClick: async (event, context) => {
            context.route.snapshot.data.detail.entity.subscribe((product: Product) => {
                window.location.pathname = `/product/presaleKey/${product.id}`
            })
        },
    }),
    addActionBarDropdownMenuItem({
        id: 'investorsForAktioniariat',
        label: 'Aktionariat XLS',
        locationId: 'product-detail',
        requiresPermission: Permission.ReadOrder,
        onClick: async (event, context) => {
            context.route.snapshot.data.detail.entity.subscribe((product: Product) => {
                window.location.pathname = `/product/investorsForAktioniariat/${product.id}`
            })
        },
    }),
    addActionBarDropdownMenuItem({
        id: 'investors',
        label: 'Investors XLS',
        locationId: 'product-detail',
        requiresPermission: Permission.ReadOrder,
        onClick: async (event, context) => {
            context.route.snapshot.data.detail.entity.subscribe((product: Product) => {
                window.location.pathname = `/product/investors/${product.id}`
            })
        },
    }),
    addActionBarDropdownMenuItem({
        id: 'testimonials',
        label: 'Testimonials',
        locationId: 'product-detail',
        requiresPermission: Permission.ReadOrder,
        onClick: async (event, context) => {
            context.route.snapshot.data.detail.entity.subscribe((product: Product) => {
                window.location.pathname = `/admin/extensions/testimonials/product/${product.id}`
            })
        },
    }),
    addActionBarDropdownMenuItem({
        id: 'reservations',
        label: 'Reservations',
        locationId: 'product-detail',
        requiresPermission: Permission.ReadOrder,
        onClick: async (event, context) => {
            context.route.snapshot.data.detail.entity.subscribe((product: Product) => {
                window.location.pathname = `/admin/extensions/reservations/product/${product.id}`
            })
        },
    }),
]
