import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextProductStatesDocument, TransitionProductToStateDocument } from '../generated/ui-types'

@Injectable()
export class ProductStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query(GetNextProductStatesDocument, { id })
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate(TransitionProductToStateDocument, {
            id,
            state,
        })
    }
}
