<div>
    <h4>Oomnium Actions</h4>
    <div>
    <a *ngIf="order?.code" [href]="'/pdf/bill/' + order?.code" target="_blink" class="btn outline" >Get Bill PDF</a>
        <a *ngIf="order?.code" [href]="'/pdf/receipt/' + order?.code" target="_blink" class="btn outline" >Get Receipt PDF</a>
    <button *ngIf="('UpdateOrder' | hasPermission)" class="btn outline" [disabled]="(entity$ | async)?.state === 'Cancelled' || (entity$ | async)?.state === 'AddingItems'" (click)="rejectOrder(  'payment-rejected')">Reject (and refund) order</button>
        <button *ngIf="('SuperAdmin' | hasPermission)" class="btn outline" [disabled]="!canBeDeleted" (click)="deleteOrder(  )">Delete order</button>

    </div>
    <div>
        Correctly Signed: Order: {{(customFields$ | async)?.correctSigningOrder ? 'Yes' : 'No'}} | Payment: {{(customFields$ | async)?.correctSigningPayment ? 'Yes' : 'No'}}
    </div>
</div>
