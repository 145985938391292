import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Dialog, SharedModule } from '@vendure/admin-ui/core'
import { Observable } from 'rxjs'

@Component({
    selector: 'vdr-product-state-select-dialog',
    templateUrl: './product-state-select-dialog.component.html',
    styleUrls: ['./product-state-select-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule],
    standalone: true,
})
export class ProductStateSelectDialogComponent implements Dialog<string> {
    resolveWith: (result?: string) => void
    nextStates: Observable<string[]>
    message = ''
    cancellable: boolean
    selectedState = ''

    select() {
        if (this.selectedState) {
            this.resolveWith(this.selectedState)
        }
    }

    cancel() {
        this.resolveWith()
    }
}
